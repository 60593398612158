<template>
  <div>
  <el-table class="table-responsive table-flush clickable-rows"
              header-row-class-name="thead-light"
              :data="formatedItems"
              @sort-change="sortByDate">
        <el-table-column label=""
                          prop="fav"
                          width="100">
            <template v-slot="{row}">
              <file-icon :mime-type="row.mimetype"></file-icon>

            </template>
        </el-table-column>
        <el-table-column :label="$t('message.fileName')"
                          sortable="custom" prop="doc" >
            <template v-slot="{row}">
                <a :href="row.url" target="_blank">{{ filename(row.doc) }}</a>
            </template>
        </el-table-column>
        <el-table-column :label="$t('message.date')" sortable="custom" prop="lastmodified" header-align="right">
          <template v-slot="{row}">
              <div class="small text-right" >{{ row.lastmodified | datetime }}</div>
          </template>
      </el-table-column>
        <el-table-column align="right" width="90" >
            <template v-slot="{row}">
                <el-dropdown trigger="click" class="dropdown">
                <span class="btn btn-sm btn-icon-only text-light">
                  <i class="fas fa-ellipsis-v mt-2"></i>
                </span>
                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                  <a class="dropdown-item" @click="$emit('delete', row)">Löschen</a>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
        </el-table-column>
    </el-table>
  </div>

</template>
<script>
  // Charts

  import FileIcon from '@/components/FileIcon/FileIcon'
  import { formatDateTime } from '@/util/data'
  import moment from 'moment'

  export default {
    name: 'FileList',
    components: {
      FileIcon
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      project: {
        type: Object,
        required: true
      }
    },
    computed: {
      colClass () {
        const width = this.items.length < this.columns ? Math.floor(12/this.items.length) : 12 / this.columns;
        return `col-md-${width}`
      },
      formatedItems () {
        const self = this
        const regex = /(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2}):(\d{2})/
        const tempItems = JSON.parse(JSON.stringify(this.items))
        let results = tempItems
          .map(i => {
            const dateParts = regex.exec(i.lastmodified)
            const modifiedDate = new Date(dateParts[3], dateParts[2]-1, dateParts[1], dateParts[4], dateParts[5], dateParts[6])
            i.lastmodified = modifiedDate
            return i
          })

        results = results.sort((rowA, rowB) => {
          if (!self.sortBy) {
              return 0
          }

          if (['lastmodified'].includes(self.sortBy)) {
              const a = moment(rowA[self.sortBy],'DD.MM.YYYY hh:mm')
              const b = moment(rowB[self.sortBy],'DD.MM.YYYY hh:mm')

              if (!a.isValid()) {
                  return 1
              }

              if (!b.isValid()) {
                  return -1
              }
              const res = self.sortOrder * ((+a) - (+b))

              return res
          }
          return self.sortOrder * rowA[self.sortBy].localeCompare(rowB[self.sortBy])
        })

        return results
      }
    },
    data() {
      return {
        sortBy: 'lastmodified',
        sortOrder: -1,
        isVersionVisible: false,
        currentFile: null,
        projectName: 'Pokalfinale 2019',
        versions: [
          {
            date: '10.11.2017 12:34'
          },
          {
            date: '1.11.2016 12:34'
          }
        ]
      }
    },
    methods: {
      filename(doc) {
        const name = decodeURIComponent(doc)
        const slash = name.lastIndexOf('/');
        return name.substring(slash+1)
      },
      sortByDate (e) {
        if (e.order === null) {
            this.sortBy = 'doc'
            this.sortOrder = 1
            return
        }

        this.sortBy = e.prop
        this.sortOrder = e.order === 'ascending' ? 1 : -1
      }
    },
  };
</script>
<style scoped>
.col-md-6 {
  flex-grow: 1;
  transition: all 400ms ease;
}

.col-md-4 {
  width: 0;
  flex-shrink: 1;
  transition: all 400ms ease;
}
</style>
