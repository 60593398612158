<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb></route-breadcrumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">

        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <card no-body v-loading="loading">
            <template slot="header">
              <div class="row">
                <div class="col-6">
                    <h3 class="mb-0">{{$t('message.documents')}}</h3>
                </div>
                <div class="col-6 text-right">
                </div>
              </div>
            </template>
             <div class="row">
                <div class="col-md-4">
                  <div class="docs-sidebar-header">

                  </div>
                  <div class="docs-sidebar">
                    <el-tree :data="folders" @node-click="folder => selectedFolder = folder"></el-tree>
                  </div>
                </div>
                <div class="col-md-8">
                  <file-list :items="folderDocs" :project="project" @delete="deleteFile"></file-list>
                  <dropzone-file-upload url="http://"
                              v-model="fileSingle" @change="upload" style="margin:10px;"
                              :options="{ dictDefaultMessage: $t('message.uploadMessage') }">
                  </dropzone-file-upload>
                </div>
              </div>
          </card>
          </div>
        </div>
      </div>
      <!-- <inline-actions-table></inline-actions-table>
      <striped-table></striped-table>
      <checkbox-table></checkbox-table>
      <checkbox-colored-table></checkbox-colored-table>
      <translucent-table></translucent-table>
      <dark-table></dark-table> -->
    </div>
  </div>
</template>
<script>
  // Charts
  import { mapGetters, mapActions } from 'vuex'
  import DropzoneFileUpload from '@/components/Inputs/DropzoneFileUpload'
  import FileList from './FileList'

  export default {
    name: 'doc-list',
    components: {
      FileList,
      DropzoneFileUpload
    },
    computed: {
      ...mapGetters([
        'bhbViewColumns',
        'project',
        'docs'
      ]),
      cols: {
        get() {
          return this.bhbViewColumns
        },
        set (columns) {
          this.$store.dispatch('setBhbViewColumns', columns)
        }
      },
      folders() {
        if (!this.project) return [];
        const result = [
          { label: 'APA', path: ['APA'] },
          // { label: this.$t('message.customer'), path: ['Kunde'], children: [
          //     { label: this.$t('message.printData'), path: ['Kunde', 'Druckdaten'] },
          //     { label: this.$t('message.various'), path: ['Kunde', 'Sonstiges'] }
          // ]},
          // { label: this.$t('message.footage'), path: ['Fotos'] }
        ];
        // const docs = _.get(this.docs, ['project', this.project.id, 'dokumente'], []);
        // docs.forEach(doc => {
        //   const path = doc.doc.split('/');
        //   // Beispiel path=['APA', 'BHB', 'doc']
        //   path.splice(-1, 1);
        //   // Beispiel path = ['APA', 'BHB']
        //   let level = result;
        //   path.forEach(dir => {
        //     let entry = level.find(entry => entry.label==dir);
        //     if (!entry) {
        //       entry = {label: dir, path: path};
        //       level.push(entry)
        //     }
        //     level = entry.children;
        //     if (!level) {
        //       level = [];
        //       entry.children = level
        //     }
        //   })
        // });
        return result;
      },
      folderDocs() {
        if (!this.project) return [];
        const base = _.get(this.docs, ['project', this.project.id, 'dokumente'], []);
        const path = (typeof this.selectedFolder.path !== 'undefined') ? this.selectedFolder.path.join('/') : '';
        return base.filter(doc => {
          const slash = doc.doc.lastIndexOf('/');
          const dir = doc.doc.substring(0, slash);
          return path==dir
        })
      }
    },
    data() {
      return {
        fileSingle: [],
        selectedFolder: {path: ['APA']},
        loading: false,
        viewMode: 'twoCols',
        projectName: 'Pokalfinale 2019',
        bhbItems: ['sss', 'sdasda','sadasd']
      }
    },
    methods: {
      ...mapActions([
        'loadProjectDocs'
      ]),
      async upload() {
        const files = {};
        let path = 'dokumente';
        if (typeof this.selectedFolder.path !== 'undefined' && this.selectedFolder.path.length>0) {
          path += '/' + this.selectedFolder.path.join('/')
        } else {
          path += '/'
        }
        files[path] = this.fileSingle;
        this.loading = true;
        await this.$store.dispatch('uploadDocument', {type:'project', id: this.project.id, files: files});
        this.loadProjectDocs(this.project.id);
        this.loading = false
      },
      async deleteFile(doc) {
        this.loading = true;
        await this.$store.dispatch('deleteDoc', doc);
        this.loadProjectDocs(this.project.id);
        this.loading = false
      }
    },
    mounted() {
      this.loadProjectDocs(this.$route.params.id)
    },
  };
</script>
<style>

.el-tree-node.is-current>.el-tree-node__content,
.el-tree-node:focus>.el-tree-node__content {
    background: #f6f9fc;
    color: #9f2f37;
}

.el-tree-node.is-current>.el-tree-node__content .el-icon-caret-right:before {
  color:#9f2f37;
}

</style>
