var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{staticClass:"table-responsive table-flush clickable-rows",attrs:{"header-row-class-name":"thead-light","data":_vm.formatedItems},on:{"sort-change":_vm.sortByDate}},[_c('el-table-column',{attrs:{"label":"","prop":"fav","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('file-icon',{attrs:{"mime-type":row.mimetype}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.fileName'),"sortable":"custom","prop":"doc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":row.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.filename(row.doc)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('message.date'),"sortable":"custom","prop":"lastmodified","header-align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"small text-right"},[_vm._v(_vm._s(_vm._f("datetime")(row.lastmodified)))])]}}])}),_c('el-table-column',{attrs:{"align":"right","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-dropdown',{staticClass:"dropdown",attrs:{"trigger":"click"}},[_c('span',{staticClass:"btn btn-sm btn-icon-only text-light"},[_c('i',{staticClass:"fas fa-ellipsis-v mt-2"})]),_c('el-dropdown-menu',{staticClass:"dropdown-menu dropdown-menu-arrow show",attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$emit('delete', row)}}},[_vm._v("Löschen")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }